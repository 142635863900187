import { render, staticRenderFns } from "./outView.vue?vue&type=template&id=4884e5b5&scoped=true"
import script from "./outView.vue?vue&type=script&lang=js"
export * from "./outView.vue?vue&type=script&lang=js"
import style0 from "./outView.vue?vue&type=style&index=0&id=4884e5b5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4884e5b5",
  null
  
)

component.options.__file = "outView.vue"
export default component.exports