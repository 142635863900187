<template>
  <div class="container bgblue">
    <!-- 输入框部分 -->
    <el-card class="form-card mt5">
      <div class="header">
        <el-row>
          <el-form :model="form" class="demo-form-inline" label-width="80px">
            <el-col :span="6">
              <el-form-item label="订单号:">
                <el-input
                  v-model="form.billCode"
                  placeholder="请输入订单号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="入库单号:">
                <el-input
                  v-model="form.orderCode"
                  placeholder="请输入入库单号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col> -->

            <el-col :span="6">
              <el-form-item label="用户姓名:">
                <el-input
                  v-model="form.agentName"
                  placeholder="请输入用户姓名"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="状态:">
                <el-select
                  v-model="form.status"
                  @change="changeStatus"
                  placeholder="请选择状态"
                  clearable
                >
                  <el-option label="已入库" value="0"></el-option>
                  <el-option label="未入库" value="1"></el-option>
                  <el-option label="已出库" value="2"></el-option>
                  <el-option label="未出库" value="3"></el-option>
                </el-select> </el-form-item
            ></el-col> -->
            <el-col :span="6">
              <el-form-item label="入库时间:">
                <el-date-picker
                  v-model="form.date"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="changeDate"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label-width="20px">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="onSubmit"
                  >查询</el-button
                >
                <el-button icon="el-icon-refresh" @click="onReset"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
          </el-form>
          <!-- <el-form :model="form" class="demo-form-inline" label-width="80px">
            <el-col :span="4">
              <el-form-item label="出库单号:">
                <el-input
                  v-model="form.orderCode"
                  placeholder="请输入出库单号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>


            <el-col :span="4">
              <el-form-item label="用户姓名:">
                <el-input
                  v-model="form.userName"
                  placeholder="请输入用户姓名"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="状态:">
                <el-select
                  v-model="form.status"
                  @change="changeStatus"
                  placeholder="请选择状态"
                  clearable
                >
                  <el-option label="已入库" value="0"></el-option>
                  <el-option label="未入库" value="1"></el-option>
                  <el-option label="已出库" value="2"></el-option>
                  <el-option label="未出库" value="3"></el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="入库时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.date"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="changeDate"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div class="last">
                <el-form-item label-width="20px">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-search"
                    @click="onSubmit"
                    >查询</el-button
                  >
                  <el-button icon="el-icon-refresh" size="mini" @click="onReset"
                    >重置</el-button
                  >
                </el-form-item>
              </div>
            </el-col>
          </el-form> -->
        </el-row>
      </div>
    </el-card>
    <!-- 表格部分 -->
    <el-card class="form-card mt5">
      <div class="table">
        <el-button type="warning" @click="onOut" icon="el-icon-tickets"
          >出库</el-button
        >
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          height="calc(100vh - 250px)"
        >
          <el-table-column align="center" prop="billCode" label="订单号">
          </el-table-column>
          <el-table-column
            align="center"
            prop="agentName"
            label="用户姓名"
            width="100"
          >
          </el-table-column>
          <!-- <el-table-column align="center" prop="bankingNumber" label="金融单号">
          </el-table-column> -->
          <el-table-column
            align="center"
            width="80"
            prop="orderType"
            label="订单类型"
          >
            <template v-slot="{ row }">
              <span v-if="row.billType == 1" style="color: #409eff"
                >入库单</span
              >
              <span v-if="row.billType == 2" style="color: #67c23a"
                >出库单</span
              >
              <span v-if="row.billType == 3" style="color: red">退货单</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="物料名称">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.productName).join(",") }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="规格/型号">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.specs).join(",") }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="品牌">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.brand).join(",") }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="功率">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.power).join(",") }}
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="单位">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.unit).join(",") }}
            </template>
          </el-table-column> -->
          <el-table-column
            align="center"
            prop="combNum"
            label="数量"
            width="70"
          >
            <template v-slot="{ row }">
              {{
                row.billItemList
                  .map((item) => Number(item.num))
                  .reduce((a, b) => {
                    return a + b;
                  }, 0)
              }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="sureTime"
            label="入库时间"
            width="140"
          >
          </el-table-column>
          <el-table-column align="center" label="状态" width="75">
            <template v-slot="{ row }">
              <el-button type="text" v-if="row.billType == 1" class="btn-orange"
                >入库</el-button
              >
              <el-button type="text" v-if="row.billType == 2" class="btn-green"
                >出库</el-button
              >
              <el-button type="text" v-if="row.billType == 3" class="btn-green"
                >退货</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="params.currPage"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totol"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 出库弹框 -->
    <div class="dialog">
      <el-dialog title="出库" :visible.sync="showDialogOut" center width="60%">
        <el-row :gutter="20">
          <el-col
            ><div>
              <span style="margin-right: 10px; font-weight: 800"
                >待出库订单列表
              </span>
              <el-button
                type="primary"
                icon="el-icon-plus"
                plain
                @click="addOrder"
                class="mt10"
                >添加出库订单</el-button
              >
            </div></el-col
          >
        </el-row>
        <div>
          <el-table :data="detailList" border>
            <el-table-column
              prop="reconnaissanceCode"
              align="center"
              label="勘探单号"
            ></el-table-column>
            <el-table-column
              prop="bankingNumber"
              align="center"
              label="金融单号"
            ></el-table-column>
            <el-table-column
              prop="orderType"
              align="center"
              label="订单类型"
            ></el-table-column>
            <el-table-column
              prop="productInfo"
              align="center"
              label="产品信息"
            ></el-table-column>
            <el-table-column
              prop="userName"
              align="center"
              label="用户姓名"
            ></el-table-column>
            <el-table-column
              prop="userPhone"
              align="center"
              width="150px"
              label="手机号"
            ></el-table-column>
            <el-table-column
              prop="realNum"
              align="center"
              label="操作"
            ></el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 20px">
          <div style="font-weight: 800">物料统计：</div>
          <el-table :data="materialData" border>
            <el-table-column
              prop="specs"
              align="center"
              label="物料类型"
            ></el-table-column>
            <el-table-column
              prop="productName"
              align="center"
              label="物料名称"
            ></el-table-column>
            <el-table-column
              prop="realNum"
              align="center"
              label="实际出库数量"
            ></el-table-column>
            <el-table-column
              prop="unit"
              align="center"
              label="物料单位"
            ></el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 10px">
          <div style="margin-bottom: 20px; font-weight: 800">备注：</div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 3 }"
            placeholder="请输入内容"
            v-model="remark"
            clearable
          >
          </el-input>
        </div>
        <div style="margin-top: 20px">
          <el-row type="flex" justify="center">
            <el-button @click="showDialogOut = false">取消</el-button>
            <el-button type="primary" @click="outSave">确认</el-button>
          </el-row>
        </div>
      </el-dialog>
    </div>
    <!-- 出库订单选择 -->
    <div class="orderDialog">
      <el-dialog title="出库订单选择" :visible.sync="showDialogOrder" center>
        <el-row class="mt10">
          <el-form :model="form" class="demo-form-inline" label-width="100px">
            <el-col :span="12">
              <el-form-item label="勘探编号:">
                <el-input
                  v-model="orderForm.reconnaissanceCode"
                  placeholder="请输入勘探编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户姓名:">
                <el-input
                  v-model="orderForm.userName"
                  placeholder="请输入用户姓名"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="手机号:">
                <el-input
                  v-model="orderForm.userPhone"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label-width="40px">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="onSubmit"
                  >查询</el-button
                >
                <el-button icon="el-icon-refresh" @click="onReset"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>

        <el-table
          :data="orderData"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="35"> </el-table-column>
          <el-table-column
            prop="reconnaissanceCode"
            align="center"
            label="勘探编号"
          ></el-table-column>
          <el-table-column
            prop="bankingNumber"
            align="center"
            label="金融单号"
          ></el-table-column>
          <el-table-column
            prop="orderType"
            align="center"
            label="订单类型"
          ></el-table-column>
          <el-table-column
            prop="productInfo"
            align="center"
            label="产品信息"
          ></el-table-column>
          <el-table-column
            prop="userName"
            align="center"
            label="用户姓名"
          ></el-table-column>
          <el-table-column
            prop="userPhone"
            align="center"
            label="手机号"
          ></el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <el-row type="flex" justify="center">
            <el-button @click="showDialogOrder = false">取消</el-button>
            <el-button type="primary" @click="saveOrder">确认</el-button>
          </el-row>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      detailList: [], //详情表格
      orderData: [], // 出库订单表格
      datailObj: {}, //详情对象
      materialData: [], //物料
      showDialogOut: false,
      showDialogOrder: false,
      orderForm: {
        reconnaissanceCode: "",
        userName: "",
        userPhone: "",
      },
      form: {
        orderCode: "",
        bankingNumber: "",
        userName: "",
        status: "",
        beginTime: "",
        endTime: "",
      },
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 10,
      },

      totol: 0,
      remark: "", //备注
    };
  },
  methods: {
    onSubmit() {
      this.params.currPage = 1;
      this.getData();
    },
    // 重置
    onReset() {
      this.form = {};
      this.getData();
    },

    async getData() {
      let condition = {
        billType: "2", //入库标识
        loginName: localStorage.getItem("loginName"),
        ...this.form,
      };
      const res = await this.$http.post("/houseWareBill/list", {
        condition,
        ...this.params,
      });
      if (res.data.code === 200) {
        this.totol = res.data.data.count;
        this.tableData = res.data.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },

    async getOrderData() {
      let condition = {
        status: 3,
        inOutFlag: 1,
        ...this.orderForm,
      };

      const {
        data: { data },
      } = await this.$http.post("/houseWareBill/listPlantform", {
        condition,
        ...this.params,
      });
      this.orderData = data.data;
    },
    // 更改当前数量
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    // 更改当前页数
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
    // 重置订单表单
    resetForm() {
      this.orderForm = {};
      this.getOrderData();
    },
    // 订单查询按钮
    async onOrderQuery() {
      this.getOrderData();
    },
    handleSelectionChange(val) {
      this.detailList = val;
    },
    // 确定订单
    async saveOrder() {
      this.showDialogOrder = false;
      const ids = this.detailList.map((item) => item.id);
      const {
        data: { data },
      } = await this.$http.post("/storeRecordBind/queryList/ids", {
        ids,
      });
      console.log(data, 382);
      this.materialData = data;
    },
    // 出库确认
    async outSave() {
      let data = {
        ids: this.detailList.map((item) => item.id),
        items: this.materialData,
        remark: this.remark,
        inOutFlag: 1,
      };
      console.log(data, 409);
      const res = await this.$http.post("/storeRecord/inout", data);
      console.log(res);
      this.$message.success("出库成功");
      this.showDialogOut = false;
    },
    addOrder() {
      this.showDialogOrder = true;
      this.onOrderQuery();
    },
    onOut() {
      this.showDialogOut = true;
      this.detailList = [];
    },
    //状态
    changeStatus(val) {
      this.form.status = val;
    },
    //日期
    changeDate(val) {
      console.log(val);
      if (val) {
        this.form.beginTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.beginTime = "";
        this.form.endTime = "";
      }
    },
  },
  created() {
    this.getData();
    // this.getOrderData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 5px;
  box-sizing: border-box;

  ::v-deep .el-input__inner {
    width: 100% !important;
  }
  .el-col-4 {
    width: 19.5%;
  }
  ::v-deep .el-select {
    width: 100% !important;
  }
  .el-table {
    margin-top: 10px !important;
  }
  ::v-deep .el-table .cell {
    line-height: 15px !important;
  }
  ::v-deep .el-dialog__header {
    background-color: #f6f8fb;
  }
  ::v-deep .el-table td {
    padding: 0.5px 0;
    box-sizing: border-box;
  }
  ::v-deep .el-form-item__label {
    font-size: 13px;
  }
  .header {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    padding-bottom: 0px !important;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #ccc;
  }
  .table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    height: calc(100vh - 160px);
  }
  .page {
    width: 100%;
    text-align: end;
    ::v-deep .el-input__inner {
      height: 25px !important;
    }
  }
  .dialog {
    ::v-deep .el-button {
      height: 25px !important;
    }
  }
}

::v-deep .el-dialog__body {
  padding-top: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}
::v-deep .el-card__body {
  padding: 0px !important;
}
::v-deep .el-card {
  border-radius: 10px;
}
</style>
